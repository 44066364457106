import moment from 'moment';
import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAccessToken,
  selectAccessTokenExpirationDate,
  selectIsRenewing,
  selectRefreshToken,
  selectRefreshTokenExpirationDate,
  selectRole,
  selectIsStreamlinedProspect
} from 'shared/store/modules/auth/selectors';

import { useAuth0Session } from 'shared/store/modules/auth/hooks/useAuth0Session';

export function useSession() {
  const { renewSession } = useAuth0Session();

  const accessToken = useSelector(selectAccessToken);
  const accessTokenExpirationDate = useSelector(selectAccessTokenExpirationDate);
  const isRenewing = useSelector(selectIsRenewing);
  const refreshToken = useSelector(selectRefreshToken);
  const refreshTokenExpirationDate = useSelector(selectRefreshTokenExpirationDate);
  const userRole = useSelector(selectRole);
  const isStreamlinedProspect = useSelector(selectIsStreamlinedProspect);

  const hasValidAccessToken = useMemo(() => {
    return Boolean(accessToken && moment().isBefore(accessTokenExpirationDate));
  }, [accessToken, accessTokenExpirationDate]);

  const hasValidRefreshToken = useMemo(() => {
    return Boolean(refreshToken && moment().isBefore(refreshTokenExpirationDate));
  }, [refreshToken, refreshTokenExpirationDate]);

  const hasValidSession = useMemo(() => {
    return hasValidAccessToken && hasValidRefreshToken;
  }, [hasValidAccessToken, hasValidRefreshToken]);

  const hasAccessToken = useMemo(() => {
    return Boolean(accessToken);
  }, [accessToken]);

  return {
    accessToken,
    hasAccessToken,
    accessTokenExpirationDate,
    hasValidAccessToken,
    hasValidRefreshToken,
    hasValidSession,
    isRenewing,
    renewSession: useCallback(() => renewSession(() => {}, 'off'), [renewSession]),
    userRole,
    isStreamlinedProspect
  };
}

